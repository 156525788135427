export const useLevelStore = defineStore('levels', {
  state: () => ({
    levels: [],
  }),
  getters: {
    exerciseLevels(state) {
      return state.levels.filter(level => level.type === 'exercise')
    },
    trainingRoomLevels(state) {
      return state.levels.filter(level => level.type === 'trainingRoom')
    },
    stretchingLevels(state) {
      return state.levels.filter(level => level.type === 'stretching')
    },
  },
  actions: {
    async fetchLevels() {
      const { $cmsFetch } = useNuxtApp()
      const result = await Promise.all([
        $cmsFetch('/level', {
          method: 'GET',
          query: {
            t: 'exercise',
          },
        }),
        $cmsFetch('/level', {
          method: 'GET',
          query: {
            t: 'trainingRoom',
          },
        }),
        $cmsFetch('/level', {
          method: 'GET',
          query: {
            t: 'stretching',
          },
        }),
      ])
      this.levels = [
        ...result[0].levels,
        ...result[1].levels,
        ...result[2].levels,
      ]
    },
  },
})
